$button-color: #efefef;

#primary-nav .level-1 {
    /*width: 13.5%;
    &:nth-child(6) {
        width: 19%;
    }*/

    #primary-nav .level-1 {
        width: 11.5%;

    }

    @include breakpoint($md) {
        width: 14%;

        &:nth-child(6) {
            width: 16%;
        }
    }

    @include breakpoint($sm) {
        padding: 8px 5px;
        width: 11.5%;

        &:nth-child(6) {
            width: 19%;
        }
    }
}

#drawer-nav .level-2 {
    @include breakpoint($sm) {
        padding-right: 10px;
    }
}

.block-menu-block {
    @include breakpoint($md) {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        -ms-column-count: 3;
        -o-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 15px;
        -moz-column-gap: 15px;
        -ms-column-gap: 15px;
        -o-column-gap: 15px;
        column-gap: 15px;
        margin-bottom: 1em;

        li.level-1:nth-child(3) {
            display: inline-block;
        }

        li.level-1:nth-child(5) {
            display: inline-block;
        }
    }
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
    max-width: none;
    height: 50px;
    border: 1px solid $wcm-border-gray;
    box-shadow: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 15px top 50%;
    background-size: 20px 20px;
    padding-right: 50px;
}

.title-bar {
    @include breakpoint($xs) {
        font-size: 22px;
        //        line-height: 2.75;
    }
}

.brand__lockup img {
    height: 84px;
}

#active-second-level-nav>.menu {
    padding-left: 0;
}

.slide-text {
    @include breakpoint($md) {
        background: rgba(85, 85, 85, 0.8);
        opacity: 1;
    }

    /*h2 {
    @include breakpoint($md) {
      padding-top: 10px;
    }
  }*/
}

.footer-address {
    @include breakpoint($sm) {
        width: 30%;
        padding-right: 10px;
    }

    @include breakpoint($md) {
        width: 23%;
    }
}

.footer-nav {
    @include breakpoint($sm) {
        width: 70%;
    }

    @include breakpoint($md) {
        width: 77%;
    }
}

/* =============================================================================
   ~Home
   ========================================================================== */

.front #main-content {

    h3,
    .pane-bundle-image img {
        padding-bottom: 20px;
    }

    .pane-bundle-text {
        p:first-child {
            margin-bottom: 0;
        }

        img {
            padding-bottom: 20px;
        }
    }

    #classic-3col-center {
        .pane-bundle-text {
            p:first-child {
                margin-bottom: 20px;
            }
        }
    }
}

/********/
.pager--infinite-scroll {
    li a {
        border-radius: 20px;
        border: 4px solid $wcm-bright-orange;
        padding: 6px 20px;
        font-size: 13px;
        width: 220px;

        &:hover,
        &:focus {
            border-color: $wcm-dark-orange;
            background-color: $wcm-bright-orange;
            color: #fff;
        }
    }
}

img.panopoly-image-full {
    margin-bottom: 0 !important;
}

.customSelect {
    background: url('../images/arrow_down.png') no-repeat 96% center #fcfffa;
    border: 1px solid #e0e0dc;
    padding: 0.3em 0.4em 0.3em 0.4em;
    width: 96%;

    @include breakpoint($sm) {
        width: 97%;
    }

    @include breakpoint($md) {
        width: 93%;
    }
}

.pane-fpid-67 {
    margin-top: 1.5em;
}

.pane-fpid-67 ul {
    padding-left: 1em;
}

.pane-uuid-e450cb7c-d3e1-4fbd-a546-9f89657e7499 {
    margin: 2.2em 0 1em;
}

.pane-fpid-77 {
    margin: -1em 0 2.5em 0;
}

ul.residency-program {
    margin: 0;
    padding: 0;
}

.residency-program li {
    list-style: none;
    margin-bottom: 1em;
    /*display: block;
	padding: 1.5em 1em 1.5em 6em;
	margin: 0 0 0.8em 0;
	@include breakpoint($sm){
	   padding: 1.5em 1em 1.5em 6em;
    }*/
    /*@include breakpoint($md){
	  	width: 68%;
	  	padding: 1.5em 1em 1.5em 6em;
	  }*/
}

.residency-program li:hover {
    //    background-color: $wcm-med-gray;
    //	  background-color: darken($button-color, 8%);
    //    cursor: pointer;
}

.residency-program li a {
    text-decoration: none;
    width: auto;
    //	  @include font-size(1.8);
}

/*.residency-program li a:hover {
	  color: $link-color;
	}*/

/*.blog {
  background: url('../images/blog.png') $button-color no-repeat 5% center;
}
.r-news {
  background: url('../images/r-news.png') $button-color no-repeat 5% center;
}*/

/* formatting for the new residents news section to make it resemble the news section */
.view-display-id-panel_pane_4 .post-date {
    font-weight: bold;
}

.view-display-id-panel_pane_4 .teaser-title {
    font-size: 2.4rem;
}

/*.rotations {
  background: url('../images/rotations.png') $button-color no-repeat 5% center;
}

.welcome {
  background: url('../images/welcome.png') $button-color no-repeat 5% center;
}
.curriculum {
  background: url('../images/curriculum.png') $button-color no-repeat 5% center;
}

.stimulation-center {
  background: url('../images/stimulation.png') $button-color no-repeat 5% center;
}*/

/*.front a > img {
  border-radius: 0;
  border: 1px solid #e1e1d7;
  margin-bottom: 0.5em;
}*/

/*h2.teaser-title {
  @include font-size(1.6);
  line-height:1.3em;
}*/


/* =============================================================================
   ~Body
   ========================================================================== */

.information-sidebar {

    h3.pane-title,
    h4 {
        color: $wcm-dark-gray;
        margin-top: 0;
        margin-bottom: 10px;
    }
}

#information-sidebar h3 {
    //  @include font-size(1.6);
    //  text-transform: uppercase;
    //  color: $text-color;
    //  font-family: "ff-meta-web-pro",sans-serif;
}

.panel-hero-3col #information-sidebar {
    //   @include font-size(1.4);
}

/*figure.hero-image {
    @include breakpoint($md){
      margin: 0 auto;
      margin-bottom: 1em;
    }
}*/

/*.hero-image figcaption {
    @include breakpoint($md){
    line-height: 1.2em;
    margin-top: 0.7em;
    }
}*/

.profile {
    @include breakpoint($md) {
        float: left;
        width: 47%;
        padding-right: 15px;
        clear: none;
        margin: 0;
        height: 180px;
        overflow: none;
    }
}

.lt-ie9 .profile {
    margin-bottom: 25px;
}

.profile img {
    float: left;
    padding: 0 0.4em 0.4em 0;
}

.profile p {
    @include breakpoint($md) {
        margin: 0;
        //    @include font-size(1.3);
    }
}

.profile-list {
    @include clearfix();
    width: 100%;
    margin-bottom: 1em;
}

.profile-list h4 {
    //	  @include font-size(1.8);
    //	  font-family: "ff-meta-web-pro",sans-serif;
    font-weight: normal;
    margin-top: 0;
}

.profile-list img {
    float: left;
    padding: 0 0.4em 0.4em 0;
}

.profile-list p {
    padding-bottom: 0;
}

.lt-ie9 .council h3 {
    @include breakpoint($md) {
        margin-top: 1em;
        background: #ffddcc;
    }
}

.view-faq {
    .row-faq-widget {
        margin-bottom: 0;
    }
}

.post-date {
    margin: 0.7em 0 0.5em 0;
}

.view-news .view-teaser,
.view-blog .view-teaser {
    border-bottom: 1px solid $wcm-border-gray;
    padding-bottom: 15px;

    .post-date {
        color: $wcm-med-gray;
        font-weight: 600;
        text-transform: uppercase;
    }
}

.front .view-news .view-teaser {
    padding-bottom: 15px;

    &:last-child {
        border-bottom: none;
    }
}

.front h2.teaser-title,
.page-news h2.teaser-title,
.page-residency-blog h2.teaser-title {
    font-size: 20px;

    //    margin-top: 0;
    a {
        //        color: $wcm-dark-gray;
    }
}

.front h4.teaser-title {
    a {
        color: inherit;
    }
}

.teaser-image img.panopoly-image-quarter {
    float: none;
    margin: 0 0 0.25em 0;
    width: auto;
    max-width: none;

    @include breakpoint($sm) {
        float: right;
        margin: 0.25em 0 1.3em 1em;
        width: 35%;
        max-width: 35%;
    }

    /*@include clearfix();
  margin-bottom: 0.8em;
  @include breakpoint($sm){
    float: right;
    margin-left: 0.5em;
    margin-right: 0;
  }
  @include breakpoint($sm){
    float: right;
    margin-left: 0.5em;
    margin-right: 0;
  }*/
}

.page-news .post-date,
.page-residency-blog .post-date {
    margin: 0.5em 0 0.5em 0;
    font-weight: bold;
}

.page-news .teaser-text p {
    padding-bottom: 0.6rem;
}

.ui-widget {
    font-size: inherit;
    //    line-height: inherit;
    font-family: inherit;
}

.ui-widget-content,
.ui-widget-header {
    background: inherit;

    //    font-size: inherit;
    a {
        color: $wcm-dark-orange;
    }
}

.ui-tabs .ui-tabs-nav li.ui-state-default {
    background: $wcm-border-gray;
}

.ui-tabs .ui-tabs-nav li.ui-state-active,
.ui-tabs .ui-tabs-nav li.ui-state-hover,
.ui-tabs .ui-tabs-nav li.ui-tabs-selected {
    background: $wcm-med-gray;
}

.ui-tabs .ui-tabs-nav li.ui-state-active a,
.ui-tabs .ui-tabs-nav li.ui-state-hover a,
.ui-tabs .ui-tabs-nav li.ui-tabs-selected a {
    color: #fff;
}

.ui-tabs .ui-tabs-panel {
    border-top: 1px solid #d8d2c9;
    padding: 1em 0;
    //    @include font-size(1.6);
    //    line-height: 2rem;
}

.pic-with-caption {
    float: right;
    margin-left: 1em;
    width: 20%;
}

.pic-with-caption .caption {
    //  @include font-size(1.4);
}

.logo-position {
    @include clearfix();
    width: 100%;
    margin-bottom: 0.5em;
    display: none;

    @include breakpoint($sm) {
        float: right;
        width: 40%;
        margin: 0 0 0.5em 0.5em;
        display: block;
    }

    @include breakpoint($md) {
        float: right;
        width: 40%;
        margin: 0 0 0.5em 0.5em;
        display: block;
    }
}

tr.even,
tr.odd,
.mobile-table tr.odd th,
.mobile-table tr.odd,
.mobile-table tr.even th,
.mobile-table tr.even {
    background-color: inherit;
    border-bottom: 1px solid #D8D2C9;
}

.even td,
.odd td,
th {
    padding: 0.5em 0.2em;
}

table.mobile-table {
    margin: 0;
}

.profile-list-image {
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 115px;
    border-radius: 0;
    border: 1px solid $wcm-border-gray;

    img {
        margin-bottom: 0;
    }
}

.views-row-odd,
.views-row-even {
    @include clearfix();
    margin-bottom: 0.5em;
}

.page-about-us-our-faculty a>img,
.page-our-people-faculty a>img,
.page-our-people-crna-roster a>img {
    border-radius: 0;
}

.page-about-us-our-faculty h2.display-name,
.page-our-people-faculty h2.display-name,
.page-our-people-crna-roster h2.display-name,
.view-profiles h2.display-name {
    font-size: 20px;
    margin-top: 0;
}

.page-about-us-our-faculty h3,
.page-our-people-faculty h3,
.page-our-people-crna-roster h3 {
    //    font-size: 18px;
    //    color: $wcm-dark-gray;
}

.poster {
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid #e2e2d8;
}

.poster img {
    margin-bottom: 0.5em;
}

.lt-ie9 .program-director-image {
    width: 150px;
    height: 200px;
}

/* =============================================================================
   ~Headshot Classes
   ========================================================================== */

.headshot {
    @include clearfix();
    //    margin-bottom: 20px;
    width: auto;

    img {
        width: 115px;
        float: left;
        margin: 3px 15px 20px 0;
    }

    .headshot-details {
        float: left;
        width: auto;
    }

    .name {
        font-weight: bold;
    }

    p {
        margin: 0;
        padding-bottom: 5px;
    }

    .title {
        padding-bottom: 10px;
    }
}

/* =============================================================================
   ~Residency Classes
   ========================================================================== */
.residency-classes .information-column {
    clear: both;

    @include breakpoint($md) {
        clear: none;
    }
}

/*.page-node-941 {
    .pane-node-body {
        @include clearfix();
    }
}*/
.resident {

    /*&:last-child {
        &:after {
            @include clearfix();
        }
    }*/

    &:nth-child(3n+1) {
        @include breakpoint($md) {
            clear: left !important;
        }
    }

    &:nth-child(2n+1) {
        @include breakpoint($sm) {
            clear: left;
        }

        @include breakpoint($md) {
            clear: none;
        }
    }

    float: none;

    @include breakpoint($sm) {
        width: 50%;
        float: left;
    }

    @include breakpoint($md) {
        width: 33%;
    }

    //  width: 240px;
    // height: 102px;
    text-align: left;
    //    margin: 10px;
    margin-bottom: 30px;
    padding: 0px 15px 5px 0px;

    img {
        float: left;
        margin: 0px 10px 10px 0px;

        @include breakpoint($sm) {
            margin: 0px 10px 40px 0px;
        }

        width: 49px;
        height: 72px;
    }

    b {
        line-height: 15px;
    }

    em {
        font-weight: bold;
    }

    .caption-text {
        display: block;
        color: $wcm-med-gray;
        font-size: 12px;
        line-height: 15px;
        padding: 0px 0px 0px 0px;
        margin: 5px 0px 0px 0px;
        text-align: left;
        vertical-align: inherit;
        text-transform: inherit;
        text-decoration: none;
        caption-side: bottom;
    }
}

// Hide in dropdown menu - too long of a list
#drawer-nav .menu-mlid-1225>ul {
    display: none;
}

// Add some space for previous class list
.page-node-938 .field-name-body li {
    padding-bottom: 10px;
}

.page-node-139 {
    #related-content-sidebar {
        div.caption-left {
            float: none;
            margin-top: 0;
        }
    }
}

#fellowships {
    // display: none;
}

.alpha-pager {
    margin: 0 0 2em;
    padding: 0;

    a {
        padding: 0 0.5em 0 0.2em;
        border-right: 1px solid $wcm-border-gray;
    }

    a.last {
        padding: 0 0 0 0.2em;
        border: none;
    }
}

.page-research-publications {

    &.page-research-publications-posters {
        h1.title {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .site-main {
        margin-left: auto;
        margin-right: auto;
        //        padding-left: 20px;
        //        padding-right: 20px;

        h1.title,
        .biblio-category-section,
        #biblio-header {
            padding-left: 20px;
            padding-right: 20px;
        }


        #biblio-header {
            a {
                padding-left: 10px;
                padding-right: 10px;

                &:first-child {
                    padding-left: 0;
                }
            }

            img {
                margin-left: -10px;
                padding-right: 10px;
                vertical-align: middle;
            }
        }

        @include breakpoint($sm) {
            width: 768px;
        }

        @include breakpoint($md) {
            width: 980px;
        }

        @include breakpoint($lg) {
            width: 1180px;
        }
    }
}

/**** Clinical Trials ****/
.node-type-wcmc-clinical-trial {

    .pane-node-field-trial-primary-investigator,
    .pane-node-field-trial-status,
    .pane-node-field-trial-category {
        .field-label {
            display: inline;
            font-weight: bold;
        }
    }
}

.view-clinical-trials {

    .views-exposed-form label {
        font-size: 13px;
    }

    #edit-status-wrapper {
        clear: both;
        border-top: 1px solid $wcm-border-gray;
        margin-top: 20px;
        padding-top: 10px;
        width: 100%;

        .form-item {
            display: inline;
            margin-right: 20px;
        }
    }

    .views-row {

        border-bottom: 1px solid $wcm-border-gray;
        padding-bottom: 20px;

        //   .views-field-title {
        //     padding-bottom: 10px;
        //   }

        .views-field-field-trial-primary-investigator,
        .views-field-field-trial-status,
        .views-field-field-trial-category {
            padding-bottom: 2px;

            .views-label {
                font-weight: 600;
            }

            .field-content {
                display: inline;
            }
        }

        .views-field-nothing {
            padding-top: 10px;
        }
    }
}

/**** Clinical Trials Publications ****/
.pane-biblio-views-panel-pane-1 {
    .views-row {
        margin-bottom: 1em;

        .biblio-title-vancouver {
            font-weight: bold;
        }
    }

    .views-exposed-form {
        margin-bottom: 40px;

        label {
            font-size: 13px;
        }
    }
}

/***** Clinical Trials Landing Page *****/
body:not(.panels-ipe-editing) {
    .panel-anesthesiology-1col .content-bottom-full .col-sm-6 .pane-bundle-image .fieldable-panels-pane div:last-child p {
        position: absolute;
    }
}

.panel-anesthesiology-1col {

    .hero-image--full {
        width: 100%;
        height: 175px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin: 0 0 20px;

        @include breakpoint($sm) {
            height: 300px;
        }
    }

    .content-main {
        margin-bottom: 20px;
    }

    .content-center-full {

        .pane-bundle-image {
            .fieldable-panels-pane {
                position: relative;
                background-color: $wcm-red;

                div:last-child {
                    color: $wcm-white;
                    max-width: 1180px;
                    width: 100%;
                    // height: 100%;
                    top: 0;
                    padding: 0 20px;
                    @include v-center();
                    position: absolute;
                    margin: 0 auto;
                    left: 0;
                    right: 0;
                }
            }
        }

        .hero-image--full {
            @include transition(all 0.25s);
            position: relative;
            width: 100%;
            height: 250px;

            // mix-blend-mode: multiply;
            background-color: $wcm-red;
            background-blend-mode: multiply;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top center;

            &.opacity-mode {
                opacity: 0.2;
            }
        }

        @supports not(mix-blend-mode: multiply) {
            .hero-image--full {
                opacity: 0.2;
            }

            // IE 10 and 11
            @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
                opacity: 0.2;
            }

        }
    }

    .content-center {
        .row {
            margin-left: 0;
            margin-right: 0;

            @include breakpoint($xs) {
                display: block;
            }
        }

        .row>[class*="col-"] {
            padding-left: 0;
            padding-right: 0;
            background: $wcm-bg-gray;
        }

        .pane-bundle-image {
            &:hover {
                img {
                    transform: scale(1.1);
                }
            }

            div {
                overflow: hidden;

                img {
                    transition: all 0.3s ease 0s;
                }
            }
        }

        .pane-bundle-text {
            width: 100%;
            padding: 0 20px;
            text-align: center;

            @include breakpoint($sm) {
                @include center();
            }
        }
    }

    .content-bottom-full {
        margin: 20px 0;

        .col-sm-6 {
            padding-left: 0;
            padding-right: 0;

            .pane-bundle-image {
                .fieldable-panels-pane {

                    &:hover {
                        img {
                            transform: scale(1.1);
                        }
                    }

                    div {
                        overflow: hidden;

                        img {
                            transition: all 0.3s ease 0s;
                        }
                    }

                    div.field-basic-image-caption {
                        font-weight: bold;
                        font-size: 16px;

                        p {
                            margin: 0;
                            position: relative;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            background: rgba(0, 0, 0, 0.3);
                        }

                        a {
                            // background: rgba(0, 0, 0, 0.3);
                            // position: absolute;
                            // width: 100%;
                            // height: 100%;
                            // top: 0;
                            text-align: center;
                            @include center();
                            color: $wcm-white;
                            padding: 10px 40px;
                            border-bottom: 1px solid;
                        }
                    }
                }
            }
        }
    }

    .btn--wcm {
        border-color: $wcm-white;
        padding: 10px 20px;
        white-space: normal;
        color: $wcm-white;

        &:before {
            line-height: 2.5;
        }

        &:hover,
        &:focus {
            background-color: $wcm-red;
        }
    }


}

/*** Profiles ***/
.view-bio-button {
    font-size: 15px;
    padding: 6px 20px;
    line-height: 1.66667;
    font-weight: normal;
    margin-top: 5px;
    border: none;
    color: $wcm-white;

    &:hover,
    &:focus {
        color: $wcm-white;
        background: $wcm-red;
    }

    &:after {
        content: '';
        display: none;
    }
}

.view-profiles {
    .view-content {

        .views-row-even,
        .views-row-odd {
            margin-bottom: 20px;

            .profile-details {
                font-size: 14px;
            }
        }
    }
}

.pane-profiles-panel-pane-5 {
    @include clearfix();

    .views-exposed-widgets {
        margin-bottom: 30px;
    }

}

.pane-profiles-panel-pane-7,
.pane-profiles-panel-pane-8,
.pane-profiles-panel-pane-9 {
    .view-content {
        h3 {
            margin-bottom: 10px;
        }

        .views-row {
            margin-bottom: 20px;
        }

        .views-field-nothing {
            float: left;

            @include breakpoint($sm) {
                width: 80%;
            }

            .profile-details {
                font-size: 14px;

                .display-name {
                    margin-top: 0;
                    font-size: 18px;
                }

                .profile-info:first-of-type {
                    &:before {
                        content: 'Contact:';
                        position: relative;
                        font-weight: bold;
                        display: block;
                        padding-top: 5px;
                    }
                }

                .btn--small {
                    margin: 10px 0;
                }

                // strong {
                //     padding-top: 5px;
                //     display: block;
                // }
            }
        }
    }
}

.pane-profiles-panel-pane-9 {
    .pane-title {
        font-size: 30px;
    }

    /*.views-row {
        font-size: 12px;
    }*/
}
