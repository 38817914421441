/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
#primary-nav .level-1 {
  /*width: 13.5%;
    &:nth-child(6) {
        width: 19%;
    }*/ }
  #primary-nav .level-1 #primary-nav .level-1 {
    width: 11.5%; }
  @media screen and (min-width: 992px) {
    #primary-nav .level-1 {
      width: 14%; }
      #primary-nav .level-1:nth-child(6) {
        width: 16%; } }
  @media screen and (min-width: 768px) {
    #primary-nav .level-1 {
      padding: 8px 5px;
      width: 11.5%; }
      #primary-nav .level-1:nth-child(6) {
        width: 19%; } }

@media screen and (min-width: 768px) {
  #drawer-nav .level-2 {
    padding-right: 10px; } }

@media screen and (min-width: 992px) {
  .block-menu-block {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    -ms-column-count: 3;
    -o-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    -ms-column-gap: 15px;
    -o-column-gap: 15px;
    column-gap: 15px;
    margin-bottom: 1em; }
    .block-menu-block li.level-1:nth-child(3) {
      display: inline-block; }
    .block-menu-block li.level-1:nth-child(5) {
      display: inline-block; } }

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }

@media screen and (max-width: 767px) {
  .title-bar {
    font-size: 22px; } }

.brand__lockup img {
  height: 84px; }

#active-second-level-nav > .menu {
  padding-left: 0; }

.slide-text {
  /*h2 {
    @include breakpoint($md) {
      padding-top: 10px;
    }
  }*/ }
  @media screen and (min-width: 992px) {
    .slide-text {
      background: rgba(85, 85, 85, 0.8);
      opacity: 1; } }

@media screen and (min-width: 768px) {
  .footer-address {
    width: 30%;
    padding-right: 10px; } }

@media screen and (min-width: 992px) {
  .footer-address {
    width: 23%; } }

@media screen and (min-width: 768px) {
  .footer-nav {
    width: 70%; } }

@media screen and (min-width: 992px) {
  .footer-nav {
    width: 77%; } }

/* =============================================================================
   ~Home
   ========================================================================== */
.front #main-content h3,
.front #main-content .pane-bundle-image img {
  padding-bottom: 20px; }

.front #main-content .pane-bundle-text p:first-child {
  margin-bottom: 0; }

.front #main-content .pane-bundle-text img {
  padding-bottom: 20px; }

.front #main-content #classic-3col-center .pane-bundle-text p:first-child {
  margin-bottom: 20px; }

/********/
.pager--infinite-scroll li a {
  border-radius: 20px;
  border: 4px solid #e7751d;
  padding: 6px 20px;
  font-size: 13px;
  width: 220px; }
  .pager--infinite-scroll li a:hover, .pager--infinite-scroll li a:focus {
    border-color: #cf4520;
    background-color: #e7751d;
    color: #fff; }

img.panopoly-image-full {
  margin-bottom: 0 !important; }

.customSelect {
  background: url("../images/arrow_down.png") no-repeat 96% center #fcfffa;
  border: 1px solid #e0e0dc;
  padding: 0.3em 0.4em 0.3em 0.4em;
  width: 96%; }
  @media screen and (min-width: 768px) {
    .customSelect {
      width: 97%; } }
  @media screen and (min-width: 992px) {
    .customSelect {
      width: 93%; } }

.pane-fpid-67 {
  margin-top: 1.5em; }

.pane-fpid-67 ul {
  padding-left: 1em; }

.pane-uuid-e450cb7c-d3e1-4fbd-a546-9f89657e7499 {
  margin: 2.2em 0 1em; }

.pane-fpid-77 {
  margin: -1em 0 2.5em 0; }

ul.residency-program {
  margin: 0;
  padding: 0; }

.residency-program li {
  list-style: none;
  margin-bottom: 1em;
  /*display: block;
	padding: 1.5em 1em 1.5em 6em;
	margin: 0 0 0.8em 0;
	@include breakpoint($sm){
	   padding: 1.5em 1em 1.5em 6em;
    }*/
  /*@include breakpoint($md){
	  	width: 68%;
	  	padding: 1.5em 1em 1.5em 6em;
	  }*/ }

.residency-program li a {
  text-decoration: none;
  width: auto; }

/*.residency-program li a:hover {
	  color: $link-color;
	}*/
/*.blog {
  background: url('../images/blog.png') $button-color no-repeat 5% center;
}
.r-news {
  background: url('../images/r-news.png') $button-color no-repeat 5% center;
}*/
/* formatting for the new residents news section to make it resemble the news section */
.view-display-id-panel_pane_4 .post-date {
  font-weight: bold; }

.view-display-id-panel_pane_4 .teaser-title {
  font-size: 2.4rem; }

/*.rotations {
  background: url('../images/rotations.png') $button-color no-repeat 5% center;
}

.welcome {
  background: url('../images/welcome.png') $button-color no-repeat 5% center;
}
.curriculum {
  background: url('../images/curriculum.png') $button-color no-repeat 5% center;
}

.stimulation-center {
  background: url('../images/stimulation.png') $button-color no-repeat 5% center;
}*/
/*.front a > img {
  border-radius: 0;
  border: 1px solid #e1e1d7;
  margin-bottom: 0.5em;
}*/
/*h2.teaser-title {
  @include font-size(1.6);
  line-height:1.3em;
}*/
/* =============================================================================
   ~Body
   ========================================================================== */
.information-sidebar h3.pane-title,
.information-sidebar h4 {
  color: #555555;
  margin-top: 0;
  margin-bottom: 10px; }

/*figure.hero-image {
    @include breakpoint($md){
      margin: 0 auto;
      margin-bottom: 1em;
    }
}*/
/*.hero-image figcaption {
    @include breakpoint($md){
    line-height: 1.2em;
    margin-top: 0.7em;
    }
}*/
@media screen and (min-width: 992px) {
  .profile {
    float: left;
    width: 47%;
    padding-right: 15px;
    clear: none;
    margin: 0;
    height: 180px;
    overflow: none; } }

.lt-ie9 .profile {
  margin-bottom: 25px; }

.profile img {
  float: left;
  padding: 0 0.4em 0.4em 0; }

@media screen and (min-width: 992px) {
  .profile p {
    margin: 0; } }

.profile-list {
  width: 100%;
  margin-bottom: 1em; }
  .profile-list:before, .profile-list:after {
    content: " ";
    display: table; }
  .profile-list:after {
    clear: both; }

.profile-list h4 {
  font-weight: normal;
  margin-top: 0; }

.profile-list img {
  float: left;
  padding: 0 0.4em 0.4em 0; }

.profile-list p {
  padding-bottom: 0; }

@media screen and (min-width: 992px) {
  .lt-ie9 .council h3 {
    margin-top: 1em;
    background: #ffddcc; } }

.view-faq .row-faq-widget {
  margin-bottom: 0; }

.post-date {
  margin: 0.7em 0 0.5em 0; }

.view-news .view-teaser,
.view-blog .view-teaser {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 15px; }
  .view-news .view-teaser .post-date,
  .view-blog .view-teaser .post-date {
    color: #666666;
    font-weight: 600;
    text-transform: uppercase; }

.front .view-news .view-teaser {
  padding-bottom: 15px; }
  .front .view-news .view-teaser:last-child {
    border-bottom: none; }

.front h2.teaser-title,
.page-news h2.teaser-title,
.page-residency-blog h2.teaser-title {
  font-size: 20px; }

.front h4.teaser-title a {
  color: inherit; }

.teaser-image img.panopoly-image-quarter {
  float: none;
  margin: 0 0 0.25em 0;
  width: auto;
  max-width: none;
  /*@include clearfix();
  margin-bottom: 0.8em;
  @include breakpoint($sm){
    float: right;
    margin-left: 0.5em;
    margin-right: 0;
  }
  @include breakpoint($sm){
    float: right;
    margin-left: 0.5em;
    margin-right: 0;
  }*/ }
  @media screen and (min-width: 768px) {
    .teaser-image img.panopoly-image-quarter {
      float: right;
      margin: 0.25em 0 1.3em 1em;
      width: 35%;
      max-width: 35%; } }

.page-news .post-date,
.page-residency-blog .post-date {
  margin: 0.5em 0 0.5em 0;
  font-weight: bold; }

.page-news .teaser-text p {
  padding-bottom: 0.6rem; }

.ui-widget {
  font-size: inherit;
  font-family: inherit; }

.ui-widget-content,
.ui-widget-header {
  background: inherit; }
  .ui-widget-content a,
  .ui-widget-header a {
    color: #cf4520; }

.ui-tabs .ui-tabs-nav li.ui-state-default {
  background: #dddddd; }

.ui-tabs .ui-tabs-nav li.ui-state-active,
.ui-tabs .ui-tabs-nav li.ui-state-hover,
.ui-tabs .ui-tabs-nav li.ui-tabs-selected {
  background: #666666; }

.ui-tabs .ui-tabs-nav li.ui-state-active a,
.ui-tabs .ui-tabs-nav li.ui-state-hover a,
.ui-tabs .ui-tabs-nav li.ui-tabs-selected a {
  color: #fff; }

.ui-tabs .ui-tabs-panel {
  border-top: 1px solid #d8d2c9;
  padding: 1em 0; }

.pic-with-caption {
  float: right;
  margin-left: 1em;
  width: 20%; }

.logo-position {
  width: 100%;
  margin-bottom: 0.5em;
  display: none; }
  .logo-position:before, .logo-position:after {
    content: " ";
    display: table; }
  .logo-position:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .logo-position {
      float: right;
      width: 40%;
      margin: 0 0 0.5em 0.5em;
      display: block; } }
  @media screen and (min-width: 992px) {
    .logo-position {
      float: right;
      width: 40%;
      margin: 0 0 0.5em 0.5em;
      display: block; } }

tr.even,
tr.odd,
.mobile-table tr.odd th,
.mobile-table tr.odd,
.mobile-table tr.even th,
.mobile-table tr.even {
  background-color: inherit;
  border-bottom: 1px solid #D8D2C9; }

.even td,
.odd td,
th {
  padding: 0.5em 0.2em; }

table.mobile-table {
  margin: 0; }

.profile-list-image {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 115px;
  border-radius: 0;
  border: 1px solid #dddddd; }
  .profile-list-image img {
    margin-bottom: 0; }

.views-row-odd,
.views-row-even {
  margin-bottom: 0.5em; }
  .views-row-odd:before, .views-row-odd:after,
  .views-row-even:before,
  .views-row-even:after {
    content: " ";
    display: table; }
  .views-row-odd:after,
  .views-row-even:after {
    clear: both; }

.page-about-us-our-faculty a > img,
.page-our-people-faculty a > img,
.page-our-people-crna-roster a > img {
  border-radius: 0; }

.page-about-us-our-faculty h2.display-name,
.page-our-people-faculty h2.display-name,
.page-our-people-crna-roster h2.display-name,
.view-profiles h2.display-name {
  font-size: 20px;
  margin-top: 0; }

.poster {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid #e2e2d8; }

.poster img {
  margin-bottom: 0.5em; }

.lt-ie9 .program-director-image {
  width: 150px;
  height: 200px; }

/* =============================================================================
   ~Headshot Classes
   ========================================================================== */
.headshot {
  width: auto; }
  .headshot:before, .headshot:after {
    content: " ";
    display: table; }
  .headshot:after {
    clear: both; }
  .headshot img {
    width: 115px;
    float: left;
    margin: 3px 15px 20px 0; }
  .headshot .headshot-details {
    float: left;
    width: auto; }
  .headshot .name {
    font-weight: bold; }
  .headshot p {
    margin: 0;
    padding-bottom: 5px; }
  .headshot .title {
    padding-bottom: 10px; }

/* =============================================================================
   ~Residency Classes
   ========================================================================== */
.residency-classes .information-column {
  clear: both; }
  @media screen and (min-width: 992px) {
    .residency-classes .information-column {
      clear: none; } }

/*.page-node-941 {
    .pane-node-body {
        @include clearfix();
    }
}*/
.resident {
  /*&:last-child {
        &:after {
            @include clearfix();
        }
    }*/
  float: none;
  text-align: left;
  margin-bottom: 30px;
  padding: 0px 15px 5px 0px; }
  @media screen and (min-width: 992px) {
    .resident:nth-child(3n+1) {
      clear: left !important; } }
  @media screen and (min-width: 768px) {
    .resident:nth-child(2n+1) {
      clear: left; } }
  @media screen and (min-width: 992px) {
    .resident:nth-child(2n+1) {
      clear: none; } }
  @media screen and (min-width: 768px) {
    .resident {
      width: 50%;
      float: left; } }
  @media screen and (min-width: 992px) {
    .resident {
      width: 33%; } }
  .resident img {
    float: left;
    margin: 0px 10px 10px 0px;
    width: 49px;
    height: 72px; }
    @media screen and (min-width: 768px) {
      .resident img {
        margin: 0px 10px 40px 0px; } }
  .resident b {
    line-height: 15px; }
  .resident em {
    font-weight: bold; }
  .resident .caption-text {
    display: block;
    color: #666666;
    font-size: 12px;
    line-height: 15px;
    padding: 0px 0px 0px 0px;
    margin: 5px 0px 0px 0px;
    text-align: left;
    vertical-align: inherit;
    text-transform: inherit;
    text-decoration: none;
    caption-side: bottom; }

#drawer-nav .menu-mlid-1225 > ul {
  display: none; }

.page-node-938 .field-name-body li {
  padding-bottom: 10px; }

.page-node-139 #related-content-sidebar div.caption-left {
  float: none;
  margin-top: 0; }

.alpha-pager {
  margin: 0 0 2em;
  padding: 0; }
  .alpha-pager a {
    padding: 0 0.5em 0 0.2em;
    border-right: 1px solid #dddddd; }
  .alpha-pager a.last {
    padding: 0 0 0 0.2em;
    border: none; }

.page-research-publications.page-research-publications-posters h1.title {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.page-research-publications .site-main {
  margin-left: auto;
  margin-right: auto; }
  .page-research-publications .site-main h1.title,
  .page-research-publications .site-main .biblio-category-section,
  .page-research-publications .site-main #biblio-header {
    padding-left: 20px;
    padding-right: 20px; }
  .page-research-publications .site-main #biblio-header a {
    padding-left: 10px;
    padding-right: 10px; }
    .page-research-publications .site-main #biblio-header a:first-child {
      padding-left: 0; }
  .page-research-publications .site-main #biblio-header img {
    margin-left: -10px;
    padding-right: 10px;
    vertical-align: middle; }
  @media screen and (min-width: 768px) {
    .page-research-publications .site-main {
      width: 768px; } }
  @media screen and (min-width: 992px) {
    .page-research-publications .site-main {
      width: 980px; } }
  @media screen and (min-width: 1200px) {
    .page-research-publications .site-main {
      width: 1180px; } }

/**** Clinical Trials ****/
.node-type-wcmc-clinical-trial .pane-node-field-trial-primary-investigator .field-label,
.node-type-wcmc-clinical-trial .pane-node-field-trial-status .field-label,
.node-type-wcmc-clinical-trial .pane-node-field-trial-category .field-label {
  display: inline;
  font-weight: bold; }

.view-clinical-trials .views-exposed-form label {
  font-size: 13px; }

.view-clinical-trials #edit-status-wrapper {
  clear: both;
  border-top: 1px solid #dddddd;
  margin-top: 20px;
  padding-top: 10px;
  width: 100%; }
  .view-clinical-trials #edit-status-wrapper .form-item {
    display: inline;
    margin-right: 20px; }

.view-clinical-trials .views-row {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 20px; }
  .view-clinical-trials .views-row .views-field-field-trial-primary-investigator,
  .view-clinical-trials .views-row .views-field-field-trial-status,
  .view-clinical-trials .views-row .views-field-field-trial-category {
    padding-bottom: 2px; }
    .view-clinical-trials .views-row .views-field-field-trial-primary-investigator .views-label,
    .view-clinical-trials .views-row .views-field-field-trial-status .views-label,
    .view-clinical-trials .views-row .views-field-field-trial-category .views-label {
      font-weight: 600; }
    .view-clinical-trials .views-row .views-field-field-trial-primary-investigator .field-content,
    .view-clinical-trials .views-row .views-field-field-trial-status .field-content,
    .view-clinical-trials .views-row .views-field-field-trial-category .field-content {
      display: inline; }
  .view-clinical-trials .views-row .views-field-nothing {
    padding-top: 10px; }

/**** Clinical Trials Publications ****/
.pane-biblio-views-panel-pane-1 .views-row {
  margin-bottom: 1em; }
  .pane-biblio-views-panel-pane-1 .views-row .biblio-title-vancouver {
    font-weight: bold; }

.pane-biblio-views-panel-pane-1 .views-exposed-form {
  margin-bottom: 40px; }
  .pane-biblio-views-panel-pane-1 .views-exposed-form label {
    font-size: 13px; }

/***** Clinical Trials Landing Page *****/
body:not(.panels-ipe-editing) .panel-anesthesiology-1col .content-bottom-full .col-sm-6 .pane-bundle-image .fieldable-panels-pane div:last-child p {
  position: absolute; }

.panel-anesthesiology-1col .hero-image--full {
  width: 100%;
  height: 175px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0 0 20px; }
  @media screen and (min-width: 768px) {
    .panel-anesthesiology-1col .hero-image--full {
      height: 300px; } }

.panel-anesthesiology-1col .content-main {
  margin-bottom: 20px; }

.panel-anesthesiology-1col .content-center-full .pane-bundle-image .fieldable-panels-pane {
  position: relative;
  background-color: #b31b1b; }
  .panel-anesthesiology-1col .content-center-full .pane-bundle-image .fieldable-panels-pane div:last-child {
    color: #fff;
    max-width: 1180px;
    width: 100%;
    top: 0;
    padding: 0 20px;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
    -ms-transform: translateY(-50%);
    /* IE 9 */
    transform: translateY(-50%);
    /* IE 10, Fx 16+, Op 12.1+ */
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0; }

.panel-anesthesiology-1col .content-center-full .hero-image--full {
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  position: relative;
  width: 100%;
  height: 250px;
  background-color: #b31b1b;
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center; }
  .panel-anesthesiology-1col .content-center-full .hero-image--full.opacity-mode {
    opacity: 0.2; }

@supports not (mix-blend-mode: multiply) {
  .panel-anesthesiology-1col .content-center-full .hero-image--full {
    opacity: 0.2; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .panel-anesthesiology-1col .content-center-full {
      opacity: 0.2; } } }

.panel-anesthesiology-1col .content-center .row {
  margin-left: 0;
  margin-right: 0; }
  @media screen and (max-width: 767px) {
    .panel-anesthesiology-1col .content-center .row {
      display: block; } }

.panel-anesthesiology-1col .content-center .row > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
  background: #f7f7f7; }

.panel-anesthesiology-1col .content-center .pane-bundle-image:hover img {
  transform: scale(1.1); }

.panel-anesthesiology-1col .content-center .pane-bundle-image div {
  overflow: hidden; }
  .panel-anesthesiology-1col .content-center .pane-bundle-image div img {
    transition: all 0.3s ease 0s; }

.panel-anesthesiology-1col .content-center .pane-bundle-text {
  width: 100%;
  padding: 0 20px;
  text-align: center; }
  @media screen and (min-width: 768px) {
    .panel-anesthesiology-1col .content-center .pane-bundle-text {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); } }

.panel-anesthesiology-1col .content-bottom-full {
  margin: 20px 0; }
  .panel-anesthesiology-1col .content-bottom-full .col-sm-6 {
    padding-left: 0;
    padding-right: 0; }
    .panel-anesthesiology-1col .content-bottom-full .col-sm-6 .pane-bundle-image .fieldable-panels-pane:hover img {
      transform: scale(1.1); }
    .panel-anesthesiology-1col .content-bottom-full .col-sm-6 .pane-bundle-image .fieldable-panels-pane div {
      overflow: hidden; }
      .panel-anesthesiology-1col .content-bottom-full .col-sm-6 .pane-bundle-image .fieldable-panels-pane div img {
        transition: all 0.3s ease 0s; }
    .panel-anesthesiology-1col .content-bottom-full .col-sm-6 .pane-bundle-image .fieldable-panels-pane div.field-basic-image-caption {
      font-weight: bold;
      font-size: 16px; }
      .panel-anesthesiology-1col .content-bottom-full .col-sm-6 .pane-bundle-image .fieldable-panels-pane div.field-basic-image-caption p {
        margin: 0;
        position: relative;
        width: 100%;
        height: 100%;
        top: 0;
        background: rgba(0, 0, 0, 0.3); }
      .panel-anesthesiology-1col .content-bottom-full .col-sm-6 .pane-bundle-image .fieldable-panels-pane div.field-basic-image-caption a {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        color: #fff;
        padding: 10px 40px;
        border-bottom: 1px solid; }

.panel-anesthesiology-1col .btn--wcm {
  border-color: #fff;
  padding: 10px 20px;
  white-space: normal;
  color: #fff; }
  .panel-anesthesiology-1col .btn--wcm:before {
    line-height: 2.5; }
  .panel-anesthesiology-1col .btn--wcm:hover, .panel-anesthesiology-1col .btn--wcm:focus {
    background-color: #b31b1b; }

/*** Profiles ***/
.view-bio-button {
  font-size: 15px;
  padding: 6px 20px;
  line-height: 1.66667;
  font-weight: normal;
  margin-top: 5px;
  border: none;
  color: #fff; }
  .view-bio-button:hover, .view-bio-button:focus {
    color: #fff;
    background: #b31b1b; }
  .view-bio-button:after {
    content: '';
    display: none; }

.view-profiles .view-content .views-row-even,
.view-profiles .view-content .views-row-odd {
  margin-bottom: 20px; }
  .view-profiles .view-content .views-row-even .profile-details,
  .view-profiles .view-content .views-row-odd .profile-details {
    font-size: 14px; }

.pane-profiles-panel-pane-5:before, .pane-profiles-panel-pane-5:after {
  content: " ";
  display: table; }

.pane-profiles-panel-pane-5:after {
  clear: both; }

.pane-profiles-panel-pane-5 .views-exposed-widgets {
  margin-bottom: 30px; }

.pane-profiles-panel-pane-7 .view-content h3,
.pane-profiles-panel-pane-8 .view-content h3,
.pane-profiles-panel-pane-9 .view-content h3 {
  margin-bottom: 10px; }

.pane-profiles-panel-pane-7 .view-content .views-row,
.pane-profiles-panel-pane-8 .view-content .views-row,
.pane-profiles-panel-pane-9 .view-content .views-row {
  margin-bottom: 20px; }

.pane-profiles-panel-pane-7 .view-content .views-field-nothing,
.pane-profiles-panel-pane-8 .view-content .views-field-nothing,
.pane-profiles-panel-pane-9 .view-content .views-field-nothing {
  float: left; }
  @media screen and (min-width: 768px) {
    .pane-profiles-panel-pane-7 .view-content .views-field-nothing,
    .pane-profiles-panel-pane-8 .view-content .views-field-nothing,
    .pane-profiles-panel-pane-9 .view-content .views-field-nothing {
      width: 80%; } }
  .pane-profiles-panel-pane-7 .view-content .views-field-nothing .profile-details,
  .pane-profiles-panel-pane-8 .view-content .views-field-nothing .profile-details,
  .pane-profiles-panel-pane-9 .view-content .views-field-nothing .profile-details {
    font-size: 14px; }
    .pane-profiles-panel-pane-7 .view-content .views-field-nothing .profile-details .display-name,
    .pane-profiles-panel-pane-8 .view-content .views-field-nothing .profile-details .display-name,
    .pane-profiles-panel-pane-9 .view-content .views-field-nothing .profile-details .display-name {
      margin-top: 0;
      font-size: 18px; }
    .pane-profiles-panel-pane-7 .view-content .views-field-nothing .profile-details .profile-info:first-of-type:before,
    .pane-profiles-panel-pane-8 .view-content .views-field-nothing .profile-details .profile-info:first-of-type:before,
    .pane-profiles-panel-pane-9 .view-content .views-field-nothing .profile-details .profile-info:first-of-type:before {
      content: 'Contact:';
      position: relative;
      font-weight: bold;
      display: block;
      padding-top: 5px; }
    .pane-profiles-panel-pane-7 .view-content .views-field-nothing .profile-details .btn--small,
    .pane-profiles-panel-pane-8 .view-content .views-field-nothing .profile-details .btn--small,
    .pane-profiles-panel-pane-9 .view-content .views-field-nothing .profile-details .btn--small {
      margin: 10px 0; }

.pane-profiles-panel-pane-9 {
  /*.views-row {
        font-size: 12px;
    }*/ }
  .pane-profiles-panel-pane-9 .pane-title {
    font-size: 30px; }

span.select2.select2-container.select2-container--default {
  width: 200px !important; }
